import React from 'react';

import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import { H1PlayerAnalytics } from '@hourone-ai/reals.organisms.player.h1-player-analytics';
import Error from 'next/error'
import { SegmentContextProviderProps } from '@hourone-ai/reals.core.analytics.segment.segment-context';
import styled from 'styled-components';
import { useTranslations } from 'next-intl';
import { up } from 'styled-breakpoints';
import { bpTheme } from '../styles/theme';
import muxBlurHash from '@mux/blurhash';
import Image from 'next/image';

const ComponentWithNoSSR = dynamic<SegmentContextProviderProps>(
    async () =>
        await import(
            '@hourone-ai/reals.core.analytics.segment.segment-context'
            ).then((comp) => comp.SegmentContextProvider),
    { ssr: false }
) as React.ElementType;

const PaddingBody = styled.div`
    padding: 20px;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: space-between;
    justify-content: space-between;
    
    ${up('md')} {
        justify-content: center;
        justify-items: center;
    }
`;

const TopBar = styled.div`
    max-width: ${bpTheme['styled-breakpoints'].breakpoints.lg};
    max-height: 42px;
    width: 100%;
    padding: 0 0 20px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex: 1;

    button {
        display: none;
    }
    ${up('md')} {
        button {
            cursor: pointer;
            display: block;
        }
    }

    img {
        cursor: pointer;
    }
`;

const BottomBar = styled.div`
    width: 100%;
    ${up('md')} {
        display: none;
    }
`;

const BottomFixedBar = styled.div<{$show: boolean}>`
    display: ${(props) => props.$show? "flex": "none"};
    position: fixed;
    width: 100%;
    right:0;
    bottom: 0;
    left: 0;
    flex-direction: row;
    padding-top: 10px;
    background-color: ${props => props.theme.palette.common.white};
    gap: 10px;
    ${up('md')} {
        padding: 10px;
        flex-direction: row;
        justify-content: space-between;
    }
`;

const BottomText = styled.p`
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: ${props => props.theme.palette.common.purple};
    flex: 1;
    display: flex;
    align-items: center;
    ${up('md')} {
        width: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const PrimaryButton = styled.button`
    background-color: ${props => props.theme.palette.common.purple};
    color: ${props => props.theme.palette.common.white};
    border-radius: 2px;
    font-size: 1.4rem;
    line-height: 2.2rem;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    ${up('md')} {
        width: 180px;
        align-self: center;
    }
`;

const SeconderyButton = styled.button`
    background-color: transparent;
    color: ${props => props.theme.palette.common.purple};
    font-size: 1.4rem;
    line-height: 2.2rem;
    padding: 8px;
    ${up('md')} {
        width: 180px;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    ${up('md')} {
        flex-direction: row;
    }
    & button {
        flex: 1;
    }
`;

const PlayerContainer = styled.div`
    max-width: ${bpTheme['styled-breakpoints'].breakpoints.lg};
    width: 100%;
    ${up('md')} {
        padding-bottom: 70px; 
    }
    border-radius: 2px;
    overflow: hidden;
    & > div {
        display: block;
        margin: auto;
        width: 100%;
        max-height: calc( 100% - 70px);
        border-radius: 2px;
        overflow: hidden;
    }

    mux-player{
        border-radius: 2px;
        overflow: hidden;
        ::part(center), [part~='center']{
            --media-icon-color: white;
            background-color: #5A5AFF;
            border-radius: 50%;
            padding: 10px; 
        }
        video,
        ::slotted([slot=media]), 
        ::slotted([slot=poster]),
        media-control-bar {
            border-radius: 2px;
        }
    }
`;

interface VideoForPlayerResponse {
    playback_id: string;
    title: string;
    created_at: Date;
    created_by_picture?: string;
    created_by_name?: string;
    tags?: string[];
    reactions?: string[];
    remixed: number;
    views?: number;
    viewers?: number;
    analytics_updated_at?: Date;
    watch_time?: number;
}

interface VideoData extends VideoForPlayerResponse {
    sourceWidth: number;
    sourceHeight: number;
    blurHashBase64?: string;
}
interface PlayerProps {
    errorCode?: number;
    data?: VideoData,
    requestId: string;
    messages: Record<string, string>;
  }

const Player = ({
    errorCode, 
    data, 
    requestId
}: PlayerProps): React.ReactElement => {
    const messages = useTranslations('Player');
    if (errorCode !== undefined || !data) {
        return <Error statusCode={errorCode || 500}/>;
    }
    return (
        <>
            <NextSeo
                title="HourOne Player"
                description="Made by Reals"
                canonical={`://${process.env.NEXT_PUBLIC_VERCEL_URL as string}/player/${requestId}`}
                openGraph={ (requestId.length > 0) ? {
                    url: `://${process.env.NEXT_PUBLIC_VIDEO_PORTAL_OG_URL as string}?id=${requestId}&env=${process.env.NEXT_PUBLIC_VERCEL_ENV as string}`,
                    title: data.title,
                    description: `Made by Reals`,
                    images: [
                        {
                            url: `https://image.mux.com/${data.playback_id}/thumbnail.png`,
                            width: 1200,
                            height: 630,
                            alt: `${data.title} preview`,
                            type: 'image/jpeg',
                        }
                    ],
                    site_name: 'HourOne Player',
                }: undefined }
            />
            <ComponentWithNoSSR writeKey={process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY as string}>
                <PaddingBody>
                    <TopBar>
                        <Image 
                            src="/logo.svg" 
                            alt="reals-logo"
                            width={96}
                            height={43}
                            onClick={() => window.location.replace(`https://app.hourone.ai?init=signUp&utm_source=hourone&utm_medium=video&utm_term=${requestId}`)}
                        />
                        <PrimaryButton 
                            onClick={() => window.location.replace(`https://app.hourone.ai?init=signUp&utm_source=hourone&utm_medium=video&utm_term=${requestId}`)}
                        >
                            {messages('actionButton')}
                        </PrimaryButton>
                    </TopBar>
                    <PlayerContainer>
                        <H1PlayerAnalytics
                            trackEventsProps={{ requestId }}
                            lazyLoad
                            metadata={{
                                // eslint-disable-next-line camelcase
                                env_key: process.env.NEXT_PUBLIC_MUX_ENV_VAR as string,
                                // eslint-disable-next-line camelcase
                                video_id: requestId,
                                // eslint-disable-next-line camelcase
                                video_title: data.title,
                                // eslint-disable-next-line camelcase
                                player_name: 'reals.me'
                            }}
                            placeholder={data?.blurHashBase64}
                            muxEnvKey={process.env.NEXT_PUBLIC_MUX_ENV_VAR as string} 
                            playbackId={data.playback_id}
                        />
                    </PlayerContainer>
                    <BottomBar>
                        <PrimaryButton 
                            onClick={() => window.location.replace(`https://app.hourone.ai?init=signUp&utm_source=hourone&utm_medium=video&utm_term=${requestId}`)}
                        >
                            {messages('actionButton')}
                        </PrimaryButton>
                    </BottomBar>
                    <BottomFixedBar $show={false}>
                        <BottomText>
                            {messages('shouldLogin')}
                        </BottomText>
                        <ButtonsWrapper>
                            <PrimaryButton>
                                {messages('PrimaryButtonLabel')}
                            </PrimaryButton>
                            <SeconderyButton>
                                {messages('SeconderyButtonLabel')}
                            </SeconderyButton>
                        </ButtonsWrapper>
                    </BottomFixedBar>
                </PaddingBody>
            </ComponentWithNoSSR>
        </>
    );
};

export default Player;

export const getServerSideProps = async (ctx: { params: { reqId: any; }; locale: string; }) : Promise<{props: PlayerProps} | null> => {
    if (ctx.params == null) {
      return null
    }
    const locale = ctx.locale;
    const requestId = ctx.params.reqId;
    const msgs = (await import(`../messages/${locale}.json`)).default;
    let parsedRes: any;
    let errorCode: number;
    try {
      const res = await fetch(`${process.env.REALS_SERVER as string}/v2/player/${requestId as string}`);
      if (!res.ok){
          return { props: { errorCode: res.status ,requestId, messages: msgs} };
        }
      const resJson: VideoForPlayerResponse = await res.json();
      const blured = await muxBlurHash(resJson.playback_id)
      parsedRes = {...resJson, ...blured};
    }
    catch (e) {
      errorCode = 500
      parsedRes = e.toString();
      return { props: { errorCode, data: parsedRes, requestId, messages: msgs} };
    }
    return { props: {data: parsedRes, requestId, messages: msgs} };
  
};
